import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from "gatsby";

import styles from "../../styles/info-site/CaseStudies.module.css";

function CaseStudies({ title, cards, subtitle }) { 
  return (
    <div className={styles.CaseStudies}>
      <h2 className={styles.CaseStudiesTitle}><FormattedMessage id={title} /></h2>
      <p><FormattedMessage id={subtitle} /></p>
      {
        cards?.length && (
          <ul className={styles.CaseStudiesList}>
            {
              cards.map(({
                id,
                title: cardTitle,
                value,
                url,
                description
              }) => (
                <li key={id.toLowerCase()} className={styles.CaseStudiesListItem}>
                  <Link to={url}>
                    <h3><FormattedMessage id={cardTitle} /></h3>
                    <h4>{value}</h4>
                    <p><FormattedMessage id={description} /></p>
                  </Link>
                </li>
              ))
            }
          </ul>
        )
      }
    </div>
  )
};

export default CaseStudies;