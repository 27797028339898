import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudies from "../../components/info-site/CaseStudies";
import GetStarted from "../../components/info-site/GetStarted";

import caseStudies from '../../data/caseStudies';

const SuccessStories = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="successStoriesTitle" descriptionId="successStoriesDescription" />
            <CaseStudies
              title="successStories"
              subtitle="caseStudiesSubtitle"
              cards={caseStudies}
            />
            <GetStarted />
        </Layout>
    );
};

export default SuccessStories;